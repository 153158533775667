import React from 'react';
import Sticker from '../../images/Sticker_Ton_Arctic.jpg';
import StickerPage from '../../components/sticker-page';


const Stickers = () => {

  const trackEmail = (e) => {
      window.analytics.identify({
        email: e.target.value
      })
  }
  
  return (
      <StickerPage
        stickerName="Arctic"
        stickerImage={Sticker}
      />
  )
};

export default Stickers;
